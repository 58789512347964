import * as Yup from "yup";

export const nameValidation = Yup.string()
  .min(2, "Name must be at least 2 characters")
  .max(50, "Name cannot exceed 50 characters")
  .matches(
    /^[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$/,
    "Enter a valid name"
  );

export const urlValidation = Yup.string().matches(
  /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*(\?.*)?\/?$/,
  "Business Website or Social Media Link is not valid"
)
export const bvnVerificationSchema = Yup.object().shape({
  bvn: Yup.string()
    .trim()
    .matches(/^\d{11}$/, "Kindly enter a valid BVN")
    .required("Kindly enter a valid BVN"),
  agreedToTerms: Yup.bool().test(
    "agreedToTerms",
    "Agree to terms & conditions",
    (value) => value === true
  )
});
export const beginsWithAlphanumericValidation = Yup.string().matches(/^[a-zA-Z0-9]+/, "Enter a valid name")

export const decimalInputRegex = /^\d*\.?\d{0,2}$/;
export const streetNameRegex = /^[a-zA-Z0-9]+(?:[\'-][a-zA-Z0-9]+)*([ ,\.][a-zA-Z0-9]+(?:[\'-][a-zA-Z0-9]+)*)*[\.]?$/;

export const linkValidation = Yup.string()
  .matches(
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
    "Enter a valid link"
  )

export const streetNameValidation = Yup.string()
  .matches(/^[a-zA-Z0-9\s',.-]+$/, "Street name can only contain letters, numbers, spaces, commas, apostrophes, periods, and hyphens");


export const phoneNumberValidation = Yup.string()
  .matches(/^(\+234|0)[789]\d{9}$/, "Enter a valid Nigerian phone number");

export const emailValidation = Yup.string()
  .email("Invalid email address")
  .min(3, "Email address must be at least 3 characters")
  .matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    "Email address domain can only contain letters, numbers, and hyphens, and cannot start or end with a hyphen"
  );

export const emailValidationSchema = Yup.object().shape({
  email: emailValidation.required()
  // .max(30, "Email address must be at most 30 characters")
});

export const otpValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .required("OTP is required")
    .matches(/^\d{6}$/, "Enter the valid OTP")
});

export const createProfileSchema = Yup.object().shape({
  firstName: nameValidation.required("First name is required"),
  lastName: nameValidation.required("Last name is required"),
  phoneNumber: phoneNumberValidation.required("Phone number is required"),
  email: emailValidation.required("Email address is required"),
  password: Yup.string()
    .required("Password is required")
    .test(
      "password-length",
      "Password must contain 8 or more characters",
      (value) => value.length >= 8
    )
    .test("lowercase", "Password must include a lowercase letter", (value) =>
      /[a-z]/.test(value)
    )
    .test("uppercase", "Password must include an uppercase letter", (value) =>
      /[A-Z]/.test(value)
    )
    .test("digit", "Password must include a digit", (value) => /\d/.test(value))
    .test(
      "special-character",
      "Password must include a special character",
      (value) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)
    ),
  referrals: Yup.string().optional(),
  password2: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  agreedToTerms: Yup.bool().test(
    "agreedToTerms",
    "Agree to terms & conditions",
    (value) => value === true
  )
});
export const businessDetailsSchema = Yup.object({
  businessName: Yup.string().min(4, "Enter a valid business name").required("Business name is required"),
  businessWebsite: urlValidation.required("Business Website or Social Media Link is required"),
  businessCategory: Yup.string().required("Kindly select business category"),
  actualCategory: Yup.string().when(
    "businessCategory",
    ([businessCategory], schema) => {
      return businessCategory === "Others"
        ? schema.required("Enter your business' category")
        : schema.notRequired();
    }
  ),
  licenseDoc: Yup.string().optional().nullable(),
  amount: Yup.string().required("Kindly enter business projection")
});
export const rejectedbusinessDetailsSchema = Yup.object({
  businessName: Yup.string().required("Business name is required"),
  businessWebsite: urlValidation.required("Business Website or Social Media Link is required"),
  businessCategory: Yup.string().required("Kindly select business category"),
  
  actualCategory: Yup.string().when(
    "businessCategory",
    ([businessCategory], schema) => {
      return businessCategory === "Others"
        ? schema.required("Enter your business' category")
        : schema.notRequired();
    }
  ),
  //   licenseDoc: Yup.string().optional(),
  amount: Yup.string().required("Kindly enter business projection"),
  licenseDocForRejection: Yup.string().optional().nullable()
});
export const businessAddressSchema = Yup.object().shape({
  houseNumber: Yup.string()
    .required("Kindly provide house number")
    .matches(/^\d+$/, "House number must be a valid number"),
  streetName: streetNameValidation.required("Kindly provide street name"),
  nearestBusStop: streetNameValidation.required("Kindly provide nearest bus stop"),
  state: Yup.string().required("State is required"),
  lga: Yup.string().required("LGA is required"),
  city: Yup.string().required("City is required"),
  // lcda: Yup.string().required("LCDA is required"),
  country: Yup.string().required("Country is required")
});
export const shareholderInformationSchema = Yup.object().shape({
  firstname: nameValidation.required("Kindly provide First Name"),
  lastname: nameValidation.required("Kindly provide Last Name"),
  sharesowned: Yup.string().required("Kindly provide Shares owned"),
  shareholderbvn: Yup.string().required("Kindly provide Share holder's bvn")
});

export const paymentDetailsSchema = Yup.object().shape({
  accountNumber: Yup.string().required("Kindly provide account number"),
  paymentMethodIds: Yup.string().required("Payment method is required"),
  accountName: Yup.string().required(),
  bankName: Yup.string().required("Select a bank")
  // bankName2: Yup.string().when('paymentMethodIds', ([paymentMethodIds],schema)=>{
  //   return paymentMethodIds.includes("1")
  //     ? schema.required("Select your bank")
  //     : schema.notRequired()
  // }),
  // accountName2: Yup.string().when('paymentMethodIds', ([paymentMethodIds],schema)=>{
  //   return paymentMethodIds.includes("1")
  //     ? schema.required("Enter your account name")
  //     : schema.notRequired()
  // }),
  // accountNumber2: Yup.string().when('paymentMethodIds', ([paymentMethodIds],schema)=>{
  //   return paymentMethodIds.includes("1")
  //     ? schema.required("Enter your account number").matches(/^\d{10}$/, "Enter a valid account number")
  //     : schema.notRequired()
  // })
});

export const documentsValidationSchema = Yup.object().shape({
  cac: Yup.mixed().test(
    "fileRequired",
    "CAC document is required",
    (file) => file !== null && file !== undefined
  ),
  mermart: Yup.mixed().test(
    "fileRequired",
    "Mermart document is required",
    (file) => file !== null && file !== undefined
  )
});

export const profileVerificationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .trim()
    .matches(
      /^(?:\+?234)?(?:0)?([7-9]\d{9})$/,
      "Kindly enter a valid phone number"
    )
    .required("Kindly enter Phone number")
    .test(
      "phone-length",
      "Kindly enter a valid phone number",
      (value) => value.length >= 8
    ),
  agreedToTerms: Yup.bool().test(
    "agreedToTerms",
    "Agree to terms & conditions",
    (value) => value === true
  )
});

export const ninVerificationSchema = Yup.object().shape({
  nin: Yup.string()
    .trim()
    .matches(/^\d{11}$/, "Kindly enter a valid NIN")
    .required("Kindly enter a valid NIN"),
  agreedToTerms: Yup.bool().test(
    "agreedToTerms",
    "Agree to terms & conditions",
    (value) => value === true
  )
});
export const unregisteredBusinessDetailsSchema = (values) =>
  Yup.object().shape({
    businessName: Yup.string()
      .required("Business name is required")
      .max(
        values?.maxBusinessNameLength || 50,
        `Business name cannot exceed ${
          values?.maxBusinessNameLength || 50
        } characters`
      ),
    businessCategory: Yup.string().required("Kindly select business category"),
    businessDescription: Yup.string()
      .min(10, "Business Description cannot be less than 10 characters")
      .required("Business Description is required"),
    BusinessProjectionValue: Yup.string().required(
      "Kindly enter business projection"
    ),
    businessWebsite: urlValidation.required(
      "Business Website or Social Media Link is required"
    ),
    actualCategory: Yup.string().when(
      "businessCategory",
      ([businessCategory], schema) => {
        return businessCategory === "Others"
          ? schema.required("Enter your business' category")
          : schema.notRequired();
      }
    )
  });

export const unregisteredBusinessAddressSchema = Yup.object().shape({
  businessHouseNumber: Yup.string()
    .required("Kindly provide house number")
    .matches(/^\d+$/, "House number must be a valid number"),
  businessStreetName: streetNameValidation.required("Kindly provide street name"),
  businessNearestBusStop: streetNameValidation.required(
    "Kindly provide nearest bus stop"
  ),
  businessState: Yup.string().required("State is required"),
  businessLga: Yup.string().required("LGA is required"),
  businessCity: Yup.string().required("City is required"),
  businessLcda: Yup.string().optional(),
  businessCountry: Yup.string().required("Country is required"),
  sameAsBusinessAddress: Yup.boolean().optional(),
  houseNumber: Yup.string()
    .required("Kindly provide house number")
    .matches(/^\d+$/, "House number must be a valid number"),
  streetName: Yup.string().required("Kindly provide street name"),
  nearestBusStop: Yup.string().required("Kindly provide nearest bus stop"),
  state: Yup.string().required("State is required"),
  lga: Yup.string().required("LGA is required"),
  city: Yup.string().required("City is required"),
  lcda: Yup.string().optional(),
  country: Yup.string().required("Country is required")

  // houseNumber: Yup.string().when(
  //   "sameAsBusinessAddress",
  //   ([sameAsBusinessAddress], schema) => {
  //     return sameAsBusinessAddress
  //       ? schema.notRequired()
  //       : schema
  //           .required("Kindly provide house number")
  //           .matches(/^\d+$/, "House number must be a valid number");
  //   }
  // ),
  // streetName: Yup.string().when(
  //   "sameAsBusinessAddress",
  //   ([sameAsBusinessAddress], schema) => {
  //     return sameAsBusinessAddress
  //       ? schema.notRequired()
  //       : schema.required("Kindly provide street name");
  //   }
  // ),
  // nearestBusStop: Yup.string().when(
  //   "sameAsBusinessAddress",
  //   ([sameAsBusinessAddress], schema) => {
  //     return sameAsBusinessAddress
  //       ? schema.notRequired()
  //       : schema.required("Kindly provide nearest bus stop");
  //   }
  // ),
  // state: Yup.string().when(
  //   "sameAsBusinessAddress",
  //   ([sameAsBusinessAddress], schema) => {
  //     return sameAsBusinessAddress
  //       ? schema.notRequired()
  //       : schema.required("State is required");
  //   }
  // ),
  // lga: Yup.string().when(
  //   "sameAsBusinessAddress",
  //   ([sameAsBusinessAddress], schema) => {
  //     return sameAsBusinessAddress
  //       ? schema.notRequired()
  //       : schema.required("LGA is required");
  //   }
  // ),
  // city: Yup.string().when(
  //   "sameAsBusinessAddress",
  //   ([sameAsBusinessAddress], schema) => {
  //     return sameAsBusinessAddress
  //       ? schema.notRequired()
  //       : schema.required("City is required");
  //   }
  // ),
  // lcda: Yup.string().optional(),
  // country: Yup.string().when(
  //   "sameAsBusinessAddress",
  //   ([sameAsBusinessAddress], schema) => {
  //     return sameAsBusinessAddress
  //       ? schema.notRequired()
  //       : schema.required("Country is required");
  //   }
  // ),
});

export const businessPaymentDetailsSchema = Yup.object().shape({
  paymentMethodIds: Yup.string().required("Payment method is required")
});



export const validateInput = async (input, validationSchema) => {
  try {
    await validationSchema.validate(input, { abortEarly: false });
    return "";
  } catch (err) {
    if (err.errors.length > 0) {
      return err.errors[0]
    }
  }
};
export const referralValidation = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required"),
  firstName: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  lastName: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Required"),
  phoneNumber: Yup.string()
    .trim()
    .matches(
      /^(?:\+?234)?(?:0)?([7-9]\d{9})$/,
      "Kindly enter a valid phone number"
    )
    .required("Kindly enter Phone number")
    .test(
      "phone-length",
      "Kindly enter a valid phone number",
      (value) => value.length >= 9
    ),
  residentialAddress: Yup.string().optional(),
  usdAccountNumber: Yup.string().optional(),
  nairaAccountNumber: Yup.string()
    .matches(/^\d+$/, "Must be a valid account number")
    .required("Kindly provide account number"),
  gender: Yup.string().required("Required"),
  password: Yup.string()
    .required("Password is required")
    .test(
      "password-length",
      "Password must contain 8 or more characters",
      (value) => value.length >= 8
    )
    .test("lowercase", "Password must include a lowercase letter", (value) =>
      /[a-z]/.test(value)
    )
    .test("uppercase", "Password must include an uppercase letter", (value) =>
      /[A-Z]/.test(value)
    )
    .test("digit", "Password must include a digit", (value) => /\d/.test(value))
    .test(
      "special-character",
      "Password must include a special character",
      (value) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
  agreedToTerms: Yup.bool().oneOf(
    [true],
    "You must accept the terms and conditions"
  )
});
export const nonReferralLoginValidation = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required")
});